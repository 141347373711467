import React from "react";

const FooterBottom = () => {
  return (
    <div className="w-full bg-[#000000] group">
      <div className="max-w-container mx-auto border-t-[1px] pt-5 pb-5">
        <p className="text-titleFont font-normal text-center flex md:items-center justify-center text-white duration-200 text-sm">
          {/* <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
            <AiOutlineCopyright />
          </span>*/
          }
          <a href="https://shoplinx.ai/" target="_blank" rel="noreferrer">
            <span className="ml-1 font-medium group-hover:text-primeColor">
              Powered by ShopLinx
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default FooterBottom;
