import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthSlice from "./AuthSlice";
import ProductSlice from "./ProductSlice";
import persistReducer from "redux-persist/es/persistReducer";
import ChatSlice from "./ChatSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  Auth: AuthSlice,
  Product: ProductSlice,
  Chat: ChatSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

const persistedStore = persistStore(store);
export { store, persistedStore };
