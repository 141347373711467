import React, { useDebugValue, useEffect, useRef, useState } from "react";
import Product from "../../home/Products/Product";
import { useDispatch, useSelector } from "react-redux";
import DescriptionModal from "../../modals/DescriptionModal";
import { GetBusinessId, GetProductDetails } from "../../../redux/ProductSlice";
import {
  checkToken,
  refreshAccessToken,
  setToken,
} from "../../../utils/tokenHandler";
import ChatIcon from "../../ChatIcon/ChatIcon";
import { clearAuth, GetTempUserAccessToken } from "../../../redux/AuthSlice";
import { useParams } from "react-router-dom";
import ProductImageModal from "../../modals/ProductImageModal";
import log from "../../../utils/logger";
import { useCallback } from "react";

const Items = React.memo(({ productList }) => {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Function to open description modal for a specific product
  const openDescriptionModal = (product) => {
    setSelectedProduct(product);
    setDescriptionModal(true);
  };

  // Function to open image modal for a specific product
  const openImageModal = (product) => {
    setSelectedProduct(product);
    setImageModal(true);
  };

  return (
    <>
      {productList.map((item, index) => (
        <div key={item.id} className="w-full">
          <Product
            index={index}
            _id={item.id}
            img={item.images[0]}
            productName={item.product_name}
            onDescriptionClick={() => openDescriptionModal(item)}
            onImageClick={() => openImageModal(item)}
          />
        </div>
      ))}
      {descriptionModal && selectedProduct && (
        <DescriptionModal
          onClose={() => setDescriptionModal(false)}
          product={selectedProduct}
        />
      )}
      {imageModal && selectedProduct && (
        <ProductImageModal
          onClose={() => setImageModal(false)}
          product={selectedProduct}
        />
      )}
    </>
  );
});

const Pagination = React.memo(() => {
  const [productList, setProductList] = useState(null);
  const [offset, setOffset] = useState(0);
  const [allChatsFetched, setAllChatsFetched] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const socket = useRef(null);
  const { businessName } = useParams();
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.Product.ProductDetails);
  const BusinessId = useSelector((state) => state.Product.BusinessId);
  const Auth = useSelector((state) => state.Auth.Auth);

  // Fetch the BusinessId based on businessName
  useEffect(() => {
    dispatch(GetBusinessId(businessName));
  }, [businessName, dispatch]);

  // Initialize WebSocket connection with stored access token on page reload
  useEffect(() => {
    const storedAccessToken = localStorage.getItem(
      `accessToken_${businessName}`
    );
    if (storedAccessToken && !socket.current) {
      socket.current = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?token=${storedAccessToken}`
      );

      //If we get an error, that means the access token has expired.
      //So, we need to get a new access token
      socket.current.addEventListener("error", (error) => {
        console.error("WebSocket connection failed:", error);
        localStorage.removeItem(`accessToken_${businessName}`);
        refreshAccessToken(businessName);
      });
      log.debug(socket.current);
      socket.current.addEventListener("open", () => {
        log.debug("WebSocket connection opened after page refresh");
      });
    }

    return () => {
      if (socket.current) {
        socket.current.close();
        log.debug(
          "WebSocket connection closed on Auth change or component unmount"
        );
      }
    };
  }, [businessName]);

  // Create WebSocket connection when access token is set in localStorage
  useEffect(() => {
    if (Auth?.success) {
      log.debug("Auth: ", Auth?.success);
      setToken(Auth.success.access_token, Auth.success.refresh_token);
      if (!socket.current) {
        socket.current = new WebSocket(
          `${process.env.REACT_APP_SOCKET_URL}?token=${Auth.success.access_token}`
        );
        socket.current.addEventListener("open", () => {
          log.debug("WebSocket connection opened");
        });
      }

      return () => {
        if (socket.current) {
          socket.current.close();
          log.debug(
            "WebSocket connection closed on Auth change or component unmount"
          );
        }
      };
    }
  }, [Auth?.success]);

  useEffect(() => {
    if (!BusinessId?.success) return;
    let sessionIdString = generateRandomString(20);
    const { access_token } = checkToken(businessName);

    if (!access_token) {
      dispatch(GetTempUserAccessToken(sessionIdString));
    }

    // Reset offset and product list before fetching new data
    setOffset(0);
    setProductList([]);

    // Fetch product details with the new offset and business ID
    dispatch(GetProductDetails({ business_id: BusinessId.success, offset: 0 }));
  }, [BusinessId?.success,dispatch, businessName]);

  // Update product list when new products are fetched
  useEffect(() => {
    if (productDetails?.success) {
      log.debug("productDetails: ", productDetails.success.length);
      setProductList((prev) => [...prev, ...productDetails.success]);
      if (productDetails.success.length < 3) {
        setAllChatsFetched(true);
      }
      setScrolling(false);
    }
  }, [productDetails?.success]);

  // Fetch more products when offset changes
  useEffect(() => {
    if (offset >= 3 && !allChatsFetched) {
      log.debug("Dispatching offset: ", offset);
      log.debug("scrolling before dispatch: ", scrolling);
      dispatch(
        GetProductDetails({ business_id: BusinessId?.success, offset: offset })
      );
    }
  }, [offset, allChatsFetched, dispatch, BusinessId?.success, scrolling]);

 

  // Handle scroll event to load more products when reaching the bottom
  const handleScroll = useCallback((e) => {  
    if (  
      !allChatsFetched &&  
      window.innerHeight + document.documentElement.scrollTop + 1 >=  
        document.documentElement.offsetHeight &&  
      !scrolling  
    ) {  
      setScrolling(true);  
      setOffset((pre) => pre + 3);  
    }  
  }, [allChatsFetched, scrolling]);

   // Attach scroll event listener to handle infinite scrolling
   useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll, scrolling]);

  // Scroll to a specific element when needed
  const makeScrollWithView = () => {
    const scrollId = document.getElementById("scrollId");
    if (scrollId) {
      scrollId.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Scroll down when the scrollDown state changes
  useEffect(() => {
    makeScrollWithView();
    setScrollDown(false);
  }, [scrollDown]);

  useEffect(() => {  
    window.addEventListener("scroll", handleScroll);  
    return () => window.removeEventListener("scroll", handleScroll);  
  }, [handleScroll]);

  // Generate a random session ID if none exists and fetch product details
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  /***************************************************************************/

  //Return a loader screen till we dont have a sessionId in the session storage

  /***************************************************************************/

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mdl:gap-4 lg:gap-5">
        {productList && <Items productList={productList} />}
      </div>
      <ChatIcon
        socket={socket}
        businessId={BusinessId?.success}
        businessName={businessName}
      />
    </div>
  );
});

export default Pagination;
