import React, { useState } from "react";
import { SiChatbot } from "react-icons/si";
import ChatModal from "../modals/ChatModal";

const ChatIcon = ({ socket, businessId, businessName }) => {
  const [Modal, setModal] = useState(false);
  return (
    <>
      <div
        className="fixed bottom-10 md:bottom-70 right-2 z-20 md:flex flex-col gap-2"
        onClick={() => setModal(true)}
      >
        <div
          className="bg-white w-[70px] h-[70px] rounded-full flex flex-col gap-1 text-[#ffffff] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer"
          style={{
            background: "linear-gradient(180deg, #1371EF 0%, #33E2C7 100%)",
          }}
        >
          <div className="flex justify-center items-center">
            <SiChatbot className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />

            <SiChatbot className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
          </div>
          {/* <p className="text-xs font-semibold font-titleFont">AI Chat</p> */}
        </div>
      </div>
      {Modal && (
        <ChatModal
          socket={socket}
          businessId={businessId}
          businessName={businessName}
          onClose={() => {
            setModal(false);
          }}
        />
      )}
    </>
  );
};

export default ChatIcon;
